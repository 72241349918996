import { Router } from 'preact-router';
import { useMachine } from 'preact-robot';

import serialMachine from '../machines/serial';
import { SerialContext } from './serial-context';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';

const App = () => {
	let machine = useMachine(serialMachine, { serial: navigator.serial });
	return (
		<SerialContext.Provider value={machine}>
			<div id="app">
				<main>
					<Router>
						<Home path="/" />
						<Profile path="/profile/" user="me" />
						<Profile path="/profile/:user" />
					</Router>
				</main>
			</div>
		</SerialContext.Provider>
	)
};

export default App;
