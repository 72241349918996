import { createMachine, state, transition, invoke, immediate, reduce, action, guard } from 'robot3';
import 'robot3/debug';
import { crc16ccitt } from 'crc';

const NABU_MAXSEGMENTSIZE = 65536;
const NABU_MAXPACKETSIZE = 1024;
const NABU_MAXPAYLOADSIZE = 991;
const NABU_HEADERSIZE = 16;
const NABU_FOOTERSIZE = 2;
const NABU_TOTALPAYLOADSIZE = NABU_MAXPAYLOADSIZE + NABU_HEADERSIZE + NABU_FOOTERSIZE;

const NABU_MSG_RESET = 0x80;
const NABU_MSG_MYSTERY = 0x81;
const NABU_MSG_GET_STATUS = 0x82;
const NABU_MSG_START_UP = 0x83;
const NABU_MSG_PACKET_REQUEST = 0x84;
const NABU_MSG_CHANGE_CHANNEL = 0x85;

const NABU_MSG_ESCAPE = 0x10;

const NABU_SERVICE_UNAUTHORIZED = 0x90;
const NABU_SERVICE_AUTHORIZED = 0x91;

const NABU_STATUS_SIGNAL = 0x01;
const NABU_STATUS_READY = 0x05;
const NABU_STATUS_GOOD = 0x06;
const NABU_STATUS_TRANSMIT = 0x1e;

const NABU_STATE_CONFIRMED = 0xe4;
const NABU_STATE_DONE = 0xe1;

const NABU_SIGNAL_STATUS_NO = 0x9f;
const NABU_SIGNAL_STATUS_YES = 0x1f;

const NABU_MSGSEQ_ACK = [NABU_MSG_ESCAPE, NABU_STATUS_GOOD];
const NABU_MSGSEQ_FINISHED = [NABU_MSG_ESCAPE, NABU_STATE_DONE];

const NABU_IMAGE_TIME = 0x007fffff;

const BASE_URL = '/proxy.php?mode=native&url=https://cloud.nabu.ca/cycle%25202%2520raw/';

const encoder = new TextEncoder();

function hex(d) {
  let arr = d?.length !== undefined ? Array.from(d).flat(3) : [d];
  return arr.map(n => "0x" + Number(n).toString(16).padStart(2, '0')).join(' ');
}

const fatalError = transition('error', 'error',
  reduce((ctx, ev) => ({ ...ctx, error: ev.error }))
);

const resetOnError = transition('error', 'reset',
  reduce((ctx, ev) => ({ ...ctx, error: ev.error })),
  action(ctx => {
    console.log(ctx.error);
  })
);

const bufferUntil = async (ctx, matchFn) => {
  while (!matchFn(ctx)) {
    let { value, done } = await ctx.reader.read();
    if (done) {
      throw new Error('got done while buffering');
    }

    console.log(`read: ${hex(value)}`);
    ctx.readBuffer.push(...value);
  }
};

const getBytes = (ctx, count) => new Promise(async r => {
  await bufferUntil(ctx, ctx => ctx.readBuffer.length >= count);
  r(ctx.readBuffer.splice(0, count), ctx);
});

const processBytes = (count, cb, nextState) => invoke(
  ctx => getBytes(ctx, count).then(result => cb(result, ctx)),
  transition('done', nextState),
  resetOnError
);

const expectToReceive = (expectValue, nextState) => invoke(
  ctx => bufferUntil(ctx, ctx => {
    let expectArray = [expectValue].flat(3);
    console.log(`want [${hex(expectArray)}] have [${hex(ctx.readBuffer)}]`);
    let l = Math.min(ctx.readBuffer.length, expectArray.length);
    // We might not have all the bytes we want, but check the ones we do have
    if (!expectArray.slice(0, l).every((v, i) => v === ctx.readBuffer[i])) {
      throw new Error(`Expected ${hex(expectValue)}, got ${hex(ctx.readBuffer)}`);
    }

    // Did we have enough to check them all?
    if (ctx.readBuffer.length < expectArray.length) return false;

    // Remove the bytes that were matched
    ctx.readBuffer.splice(0, expectArray.length);
    return true;
  }),
  transition('done', nextState),
  resetOnError
);

const sendBytes = (byteArray, nextState) => invoke(
  ctx => ctx.writer.write(new Uint8Array([byteArray].flat(3))),
  transition('done', nextState),
  resetOnError
);

const escape0x10 = inBuf =>
  new Uint8Array([...inBuf].map(v => v === NABU_MSG_ESCAPE ? [NABU_MSG_ESCAPE, v] : v).flat());

const dispatch = (expectMsg, nextState) => transition('done', nextState,
  guard(ctx => {
    if (ctx.readBuffer[0] === expectMsg) {
      ctx.readBuffer.shift();
      console.log(`-> ${nextState}`);
      return true;
    }
  })
);

const processMessages = invoke(
  ctx => bufferUntil(ctx, ctx => ctx.readBuffer.length),
  dispatch(NABU_MSG_RESET, 'handleResetMsg'),
  dispatch(NABU_MSG_START_UP, 'sendInit'),
  dispatch(NABU_MSG_GET_STATUS, 'sendStatusGood'),
  dispatch(NABU_STATUS_SIGNAL, 'sendChannelStatus'),
  dispatch(NABU_STATUS_TRANSMIT, 'sendFinished'),
  dispatch(NABU_MSG_MYSTERY, 'sendMysteryAck'),
  dispatch(NABU_MSG_PACKET_REQUEST, 'sendPacketReqAck'),
  dispatch(NABU_MSG_CHANGE_CHANNEL, 'sendChangeChannelAck'),
  transition('done', 'reset', action(ctx => {
    console.log(`Unhandled NABU message ${hex(ctx.readBuffer[0])}`);
  })),
  resetOnError
);

const machine = createMachine({
  start: state(immediate('gettingPorts')),

  error: state(immediate(
    'stopped',
    action(ctx => {
      console.log("fatal error: ", ctx.error);
    })
  )),

  stopped: state(),

  // Check if we already have access to a port
  gettingPorts: invoke(ctx => ctx.serial.getPorts(),
    transition('done', 'validatingPorts',
      reduce((ctx, ev) => ({ ...ctx, foundPorts: ev.data }))
    ),
    fatalError),

  // If we don't have a port, we need to ask for one.
  validatingPorts: state(
    immediate('opening',
      guard(ctx => ctx.foundPorts?.length),
      action(ctx => {
        console.log("found a port, skipping request", ctx.foundPorts);
        ctx.port = ctx.foundPorts[0];
      })
    ),
    immediate('selecting')),

  // Park here because the request needs to be user-initiated.
  // Somewhere we need an onClick that triggers the `request` transition.
  selecting: state(
    transition('request', 'requesting')
  ),

  // Ask the user for access to a serial port.
  requesting: invoke(ctx => ctx.serial.requestPort(),
    transition('done', 'opening',
      reduce((ctx, ev) => {
        console.log(ev);
        return { ...ctx, port: ev.data }
      })
    ),
    transition('error', 'selecting')),

  // Now we have a port, so open it
  opening: invoke(ctx => ctx.port.open({ baudRate: ctx.baud, dataBits: 8, stopBits: 2, parity: 'none' }),
    transition('done', 'opened'),
    fatalError
  ),

  opened: state(
    immediate('processMessages',
      guard(ctx => ctx.port.readable && ctx.port.writable),
      action(ctx => {
        ctx.reader = ctx.port.readable.getReader();
        ctx.writer = ctx.port.writable.getWriter();
        ctx.readBuffer = [];
        delete ctx.image;
      })
    ),
    immediate('closed')
  ),

  reset: state(
    immediate('opened', action(ctx => {
      console.log("RESET");
      ctx.reader?.releaseLock();
      ctx.writer?.releaseLock();
    }))
  ),

  closed: state(
    immediate('start', action(() => { alert('port was closed') }))
  ),

  processMessages: processMessages,

  handleResetMsg: sendBytes([NABU_MSGSEQ_ACK, NABU_STATE_CONFIRMED], 'reset'),

  sendInit: sendBytes([NABU_MSGSEQ_ACK, NABU_STATE_CONFIRMED], 'processMessages'),
  sendStatusGood: sendBytes(NABU_MSGSEQ_ACK, 'processMessages'),
  sendChannelStatus: sendBytes([NABU_SIGNAL_STATUS_YES, NABU_MSGSEQ_FINISHED], 'processMessages'),
  sendConfirmed: sendBytes(NABU_STATE_CONFIRMED, 'processMessages'),
  sendFinished: sendBytes(NABU_MSGSEQ_FINISHED, 'processMessages'),

  sendMysteryAck: sendBytes(NABU_MSGSEQ_ACK, 'getMysteryBytes'),
  getMysteryBytes: processBytes(2, bytes => console.log(`mystery bytes: [${hex(bytes)}]`), 'sendConfirmed'),

  sendChangeChannelAck: sendBytes(NABU_MSGSEQ_ACK, 'handleChangeChannel'),
  handleChangeChannel: processBytes(2, bytes => console.log(`change channel: [${hex(bytes)}]`), 'sendConfirmed'),

  sendPacketReqAck: sendBytes(NABU_MSGSEQ_ACK, 'handlePacketReq'),
  handlePacketReq: invoke(
    ctx => getBytes(ctx, 4).then(bytes => {
      console.log(`packet req: ${hex(bytes)}`);
      let segment = bytes[0];
      let imageId = bytes[3] << 16 | bytes[2] << 8 | bytes[1];

      if (ctx?.image?.imageId !== imageId) {
        console.log("preparing new image");
        ctx.image = { imageId: imageId };
      }
      ctx.image.segment = segment;
      console.log(`segment ${hex(segment)} image ${hex(imageId)}`);
    }),
    transition('done', 'sendPacketUnauthorized', guard(ctx => ctx.image.imageId === NABU_IMAGE_TIME)),
    transition('done', 'sendPacketAuthorized'),
    resetOnError
  ),
  sendPacketAuthorized: sendBytes([NABU_STATE_CONFIRMED, NABU_SERVICE_AUTHORIZED], 'awaitPacketAck'),
  sendPacketUnauthorized: sendBytes([NABU_STATE_CONFIRMED, NABU_SERVICE_UNAUTHORIZED], 'awaitUnauthAck'),
  awaitUnauthAck: expectToReceive([NABU_MSGSEQ_ACK], 'processMessages'),
  awaitPacketAck: expectToReceive([NABU_MSGSEQ_ACK], 'preparePacket'),

  preparePacket: invoke(ctx => {
    return new Promise((resolve, reject) => {
      // Ensure we have the PAK file
      if (ctx.image.pak) return resolve(ctx.image.pak);

      console.log("Fetching image remotely");
      resolve(fetch(`${BASE_URL}${ctx.image.imageId.toString(16).padStart(6, '0')}.pak`)
        .then(response => response.arrayBuffer()))
    })
      .then(pak => {
        ctx.image.pak = pak;

        const segment = ctx.image.segment;
        let last = false;

        let len = NABU_TOTALPAYLOADSIZE;
        let off = (segment * len) + ((2 * segment) + 2);

        if (off >= pak.byteLength) {
          return reject(`offset ${off} exceeds PAK size ${pak.byteLength}`);
        }

        if (off + len >= pak.byteLength) {
          len = pak.byteLength - off;
          last = true;
        }

        let buf = new Uint8Array(pak.slice(off, off + len));

        let crc = crc16ccitt(buf.subarray(0, len - 2)) ^ 0xffff;
        new DataView(buf.buffer).setUint16(len - 2, crc);

        ctx.image.buf = buf;
      });
  },
    transition('done', 'sendPacket'),
    resetOnError),

  sendPacket: invoke(
    ctx => ctx.writer.write(escape0x10(ctx.image.buf)),
    transition('done', 'sendFinished'),
    resetOnError
  )
},
  initialContext => ({ baud: 111816, ...initialContext }));

export default machine;
